<script setup>
import { computed, onBeforeUnmount, onMounted, onUnmounted, ref } from "vue";
import { useRoute } from "vue-router";

import LoginModal from "../modal/LoginModal.vue";
import RegisterModal from "../modal/RegisterModal.vue";
import DepositResultModal from "../modal/DepositResultModal.vue";
import PasswordResetModal from "../modal/PasswordResetModal.vue";
import { useBetStore } from "../../../../store";
import AppHeaderDivisionLinks from "./AppHeaderDivisionLinks.vue";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import { useAuth } from "@/user-account/composables/useAuth";
import UserWallet from "@/user-account/components/UserWallet.vue";
import { useI18n } from "@/app/composables/useI18n";
import { useConfig } from "@/app/composables/useConfig";
import MyBet from "@/user-account/components/MyBet.vue";
import BetModal from "@/app/components/modal/BetModal.vue";
import CasinoBonusModal from "@/app/components/modal/CasinoBonusModal.vue";
import ChatModal from "@/app/components/modal/ChatModal.vue";
import BonusNull from "@/app/components/modal/BonusNull.vue";
import BetPlacedModal from "@/app/components/modal/BetPlacedModal.vue";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { ticketsPayload } from "@/sportsbook/utils/betslipBetPlacement";
import { usePromotion } from "@/app/composables/usePromotion";
import { brands } from "@/app/utils/brands";
import { useFreeBets } from "@/sportsbook/composables/useFreeBets";
import FreeModal from "../modal/FreeModal.vue";
import { useLogoBanner } from "@/app/composables/useLogoBanner";

const route = useRoute();

const { isDrawerOpen } = useConfig();

const store = useBetStore();
const { getActiveBonus, activeBonus } = usePromotion();
const { freeBets } = useFreeBets();

const { t } = useI18n();

const { isAuthenticated } = useAuth();

const showPasswordResetModal = ref(false);
const showRegisterModal = ref(false);
const showBetModal = ref(false);
const showBonusNull = ref(false);

const showDepositResultModal = ref(false);

const screenWidth = ref(window.innerWidth);

const showLoginModal = computed(() => store.login);

function handleResize() {
  screenWidth.value = window.innerWidth;
}

const back = ref(false);

onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize(); // Initial check

  if (route.query.next_action === "showDepositResultModal") {
    showDepositResultModal.value = true;
  }
});

function handleShowLoginModal() {
  store.handleShowLogin();
  if (back.value) {
    back.value = false;
  }
}

function handleShowLoginWithBack() {
  store.handleShowLogin();
  back.value = true;
}

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

function onPasswordReset() {
  showPasswordResetModal.value = true;
  store.handleShowLogin();
}

const showFreeBetModal = ref(false);

const handleShowFreeModal = () => {
  if (isAuthenticated()) {
    if (activeBonus.value) {
      showFreeBetModal.value = !showFreeBetModal.value;
    } else {
      // alert("Active bonus is null");
      showBonusNull.value = true;
    }
  } else {
    store.handleShowLogin();
  }
};

function handleShowModal() {
  if (isAuthenticated()) {
    store.handleShowBetModal();
  } else {
    handleShowLoginModal();
  }
}

function handleShowModalAndBet() {
  if (isAuthenticated()) {
    if (store.betModal && store.section == "bet") {
      store.handleShowBetModal();
    } else if (store.betModal && store.section != "bet") {
      store.setSection("bet");
    } else {
      handleShowModal();
      store.setSection("bet");
    }
  } else {
    // handleShowLoginModal();
  }
}

const isVisibleDivisionLinks = computed(() => {
  if (store.getScreenWidth < 568) {
    return false;
  }

  const modules = store.getModules;

  return modules?.length > 1;
});
const brandName = import.meta.env.VITE_APP_BRAND;

onMounted(() => getActiveBonus());
const { logo } = useLogoBanner();
</script>

<template>
  <DepositResultModal
    :show="showDepositResultModal"
    @open-deposit-modal="() => (showDepositResultModal = true)"
    @close="() => (showDepositResultModal = false)"
  />

  <LoginModal
    :show="showLoginModal"
    @reset-password="onPasswordReset"
    @close="handleShowLoginModal"
    @open-register-modal="showRegisterModal = true"
    :back="back"
  />
  <RegisterModal
    :show="showRegisterModal"
    @open-login-modal="handleShowLoginWithBack"
    @close="() => (showRegisterModal = false)"
  />
  <PasswordResetModal
    :show="showPasswordResetModal"
    @close="() => (showPasswordResetModal = false)"
  />
  <BetPlacedModal :show="store.getTickets" :tickets="ticketsPayload" v-if="store.getTickets" />
  <BonusNull :show="showBonusNull" @close="() => (showBonusNull = false)" />
  <ChatModal :show="store.chatModal" @close="store.handleShowChatModal" />
  <FreeModal :show="showFreeBetModal" @close="handleShowFreeModal" />

  <CasinoBonusModal :show="showFreeBetModal" @close="handleShowFreeModal" :bonus="activeBonus" />

  <header class="app-header" @click="() => store.handleShowBetModal(false)">
    <div class="left-panel">
      <!-- <div v-if="screenWidth >= 568">
        <BaseIcon
          class="hamburger"
          name="hamburger"
          size="24"
          color="#eee"
          @click="isDrawerOpen = !isDrawerOpen"
        />
      </div> -->
      <div class="logo">
        <router-link :class="brandName == 'sojibet' ? 'soji-logo' : 'brand-logo'" to="/">
          <img :src="logo" alt="" :class="{ 'mobile-logo': screenWidth < 568 }" />
        </router-link>
      </div>
      <div v-if="screenWidth >= 568">
        <BaseIcon
          class="hamburger"
          :name="isDrawerOpen ? 'shift' : 'menu-open'"
          size="24"
          color="#eee"
          @click="isDrawerOpen = !isDrawerOpen"
        />
      </div>
      <div v-if="screenWidth >= 568" class="bonus-btn" @click="handleShowFreeModal">
        <img src="/assets/gift.svg" class="bonus-img" />
        <div>{{ t("general.bonuses") }}</div>
      </div>
      <AppHeaderDivisionLinks v-if="isVisibleDivisionLinks" />
    </div>
    <!-- <div @click.stop class="close-menu" v-if="screenWidth <= 568" @click="handleShowModalAndBet">
      <img src="/assets/images/menu-close.svg" alt="" />
    </div> -->
    <div class="right-panel-wrapper">
      <UserWallet @click.stop v-if="isAuthenticated()" />

      <div v-if="!isAuthenticated()" class="right-panel">
        <div class="register-btn" @click="() => (showRegisterModal = true)">
          {{ t("account.auth_register") }}
        </div>
        <BaseButton class="auth-button alight" @click="handleShowLoginModal" @click.stop>
          {{ t("account.auth_login") }}
        </BaseButton>
      </div>
      <div v-else class="right-panel">
        <div v-if="screenWidth >= 568">
          <MyBet @click.stop />
        </div>
      </div>
      <!-- <div class="block" @click="() => store.handleShowChatModal()" v-if="screenWidth >= 568">
        <img src="/assets/images/chat.svg" alt="" />
      </div> -->
    </div>
  </header>
  <BetModal
    @open-register-modal="() => (showRegisterModal = true)"
    @close="() => (showBetModal = false)"
  />
</template>

<style lang="scss" scoped>
.app-header {
  background-color: var(--be-small-card-bg);
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  padding: 0 5px;
  height: 60px;
  min-width: 100%;
  width: 100%;

  .close-menu {
    background: #48ff91;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
  }

  .bonus-btn {
    border-radius: 4px;
    gap: 5px;
    background: var(--Linear, linear-gradient(180deg, #fff9a8 0%, #ffaefe 100%));
    width: 110px;
    height: 36px;
    display: flex;
    align-items: center;
    color: #213743;
    font-family: Rubik-med;
    font-size: 14px;
    position: relative;
    margin-left: 17px;
    cursor: pointer;

    .bonus-img {
      top: -6px;
      right: 2px;
      left: 2px;
      position: relative;
    }
  }

  @media screen and (max-width: 580px) {
    .bonus-btn {
      width: 40px;
      height: 40px;
      margin-left: 0px;
      .bonus-img {
        top: initial;
        left: 3px;
        right: initial;
        width: 26px;
        top: initial;
        left: initial;
        right: initial;
      }
    }
  }

  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #333360;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }

  .brand-logo {
    width: 170px;
    height: 24px;
  }

  .register-btn {
    font-family: Poppins;
    font-size: 15px;
    border-radius: 4px;
    width: 95.8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  @media screen and (max-width: 390px) {
    .register-btn {
      width: 55%;
    }
  }
  .register-btn:hover {
    // background: #222143;
    height: 36px;
  }
  .left-panel {
    display: flex;
    align-items: center;

    .hamburger {
      margin: 0 10px 0 10px;
      cursor: pointer;
    }

    .logo {
      display: flex;
      align-items: center;
      margin-right: 20px;

      img {
        display: block;
        max-width: 100%;
        max-height: 35px;
        padding-left: 20px;
        .tablet & {
          padding-left: 0;
        }

        &.mobile-logo {
          max-width: 150px;
        }

        @media screen and (max-width: 390px) {
          &.mobile-logo {
            padding-left: 8px;
          }
        }

        @media screen and (max-width: 380px) {
          &.mobile-logo {
            max-width: 130px;
          }
        }
        @media screen and (max-width: 360px) {
          &.mobile-logo {
            max-width: 120px;
          }
        }

        @media screen and (max-width: 347px) {
          &.mobile-logo {
            max-width: 100px;
          }
        }
      }
    }

    @media screen and (max-width: 580px) {
      .logo {
        margin-right: 0px;

        img {
          padding-left: 16px;
        }
      }

      .brand-logo {
        height: 16px;
      }
    }
  }

  @media screen and (max-width: 390px) {
    .left-panel {
      width: 35%;
    }
  }

  .right-panel-wrapper {
    display: flex;
    align-items: center;

    .language-selector {
      flex-shrink: 0;
      margin: 0 10px 0 0;
      position: absolute;
      left: 271px;
      top: 61px;
      // height: 300px;
      background: var(--be-bg);
      overflow: auto;
    }

    .right-panel {
      display: flex;
      align-items: center;
      .header-wallet-select {
        width: 150px;
      }

      .wallet-button {
        margin-right: 7px;
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 15px;
        border-radius: 2px;
        margin-top: -1px;
        cursor: pointer;

        .title {
          margin-left: 4px;
        }
      }
      .auth-button {
        text-transform: uppercase;
        margin: 0 8px 0px 0px;
        font-size: 0.8em;
      }

      @media screen and (max-width: 390px) {
        .auth-button {
          width: 100%;
          padding: 0px;
          margin-left: 10px;
        }
      }
    }

    @media screen and (max-width: 390px) {
      .right-panel {
        margin: 0px;
      }
    }
  }

  @media screen and (max-width: 390px) {
    .right-panel-wrapper {
      justify-content: flex-end;
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 360px) {
    .right-panel-wrapper {
      justify-content: flex-end;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 580px) {
  .app-header {
    align-items: center;
  }
}
</style>
