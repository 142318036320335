<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import GameCard from "./GameCard.vue";
import { useCasinoService } from "@/casino/composables/useCasinoService.js";
import { useRoute } from "@/app/router";

const casinoService = useCasinoService();
const scrollComponent = ref(null);
const route = useRoute();
const providerId = computed(() => route.params.providerId);

const portionSize = 40;
const portion = ref(1);
const visibleGames = computed(() => {
  return casinoService?.games?.value?.slice(0, portionSize * portion.value);
});

watch(casinoService.games, () => {
  portion.value = 1;
});

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

function handleScroll() {
  if (scrollComponent.value.getBoundingClientRect().bottom < window.innerHeight) {
    portion.value++;
  }
}
</script>

<template>
  <div ref="scrollComponent" class="games-container">
    <div v-if="providerId" class="provider-header">{{ providerId }}</div>
    <div class="games-grid">
      <GameCard v-for="game in visibleGames" :key="game.id" :game="game" />
    </div>
    <!-- <div v-for="game in visibleGames" :key="game.id" :game="game">1</div> -->
  </div>
</template>

<style lang="scss" scoped>
.provider-header {
  margin-bottom: 10px;
  text-transform: capitalize;
}

.games-container {
  padding: 30px 16px;
  background-color: var(--be-big-card-bg);

  border-radius: 8px;
  margin-top: 10px;
}
.games-grid {
  display: grid;
  // flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 16px;

  // &::after {
  //   content: "";
  //   flex: auto;
  // }
}

@media screen and (max-width: 550px) {
  .games-grid {
    padding: 0px;
    margin: 3px 16px 0 16px;
    background: transparent;
    gap: 8px;
  }
}
</style>
