import { jsonRpc } from "@/services/json-rpc/jsonrpc";

const casino = {
  async games(params) {
    const games = await jsonRpc({
      method: "casino.games:list",
      params,
    });

    return games.list;
  },

  async updateFavourite(params) {
    return await jsonRpc({
      method: "casino.favourites:update",
      params,
    });
  },

  async game(params) {
    return await jsonRpc({
      method: "casino.game:show",
      params,
    });
  },

  async gameInit(params) {
    return await jsonRpc({
      method: "casino.game:init",
      params,
    });
  },

  async providers(categoryId = undefined) {
    const providers = await jsonRpc(
      {
        method: "casino.providers:list",
        params: {
          paginator: {
            cp: 1,
            rpp: 100,
          },
          categoryId: categoryId == 0 ? undefined : categoryId,
          status: 1,
        },
      },
      { uri: "casino.providers:list" }
    );
    return providers.providers.map((provider) => {
      return {
        ...provider,
        slug: provider.name.toLowerCase().replaceAll(" ", "-"),
      };
    });
  },

  async categories() {
    const categories = await jsonRpc(
      {
        method: "utils.resources:list",
        params: {
          database: "slotegrator",
          resource: "category",
          where: "status != 0",
        },
      },
      { uri: "casino.categories:list" }
    );
    return categories.map((category) => {
      return {
        ...category,
        slug: category.name.toLowerCase().replaceAll(" ", "-"),
      };
    });
  },
};

export default casino;
